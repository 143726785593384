import { ElMessage } from 'element-plus'

/**
 * 格式化日期
 * @param {string | number | Date} value 指定日期
 * @param {string} format 格式化的规则
 * @example
 * ```js
 * formatDate();
 * formatDate(1603264465956);
 * formatDate(1603264465956, "h:m:s");
 * formatDate(1603264465956, "Y年M月D日");
 * ```
 */
export function formatDate(value = Date.now(), format = 'Y-M-D h:m:s') {
  const formatNumber = (n) => `0${n}`.slice(-2)
  const date = new Date(value)
  const formatList = ['Y', 'M', 'D', 'h', 'm', 's']
  const resultList = []
  resultList.push(date.getFullYear().toString())
  resultList.push(formatNumber(date.getMonth() + 1))
  resultList.push(formatNumber(date.getDate()))
  resultList.push(formatNumber(date.getHours()))
  resultList.push(formatNumber(date.getMinutes()))
  resultList.push(formatNumber(date.getSeconds()))
  for (let i = 0; i < resultList.length; i++) {
    format = format.replace(formatList[i], resultList[i])
  }
  return format
}

export function handleExport(res, filename = '列表') {
  console.log(res)
  if (res.status && res.status !== 200) {
    ElMessage.error('下载失败')
  } else {
    try {
      const enc = new TextDecoder('utf-8')
      const data = JSON.parse(enc.decode(new Uint8Array(res.data))) || ''
      if (+data.code === 403 || data.code === 1) {
        ElMessage.error(data.message)
      }
    } catch (error) {
      console.log('发生了error', error)
      const blob = new Blob([res.data])
      const elink = document.createElement('a')
      elink.download = `${filename}.xlsx`
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    }
  }
}

export function formatPrice(val) {
  return (val / 100).toFixed(2)
}
