// 创建用户规则
export const createRules = {
  name: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入店铺名称'
    }
  ],
  phone: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入手机号'
    },
    {
      pattern:
        /^(13[0-9]|15[012356789]|166|165|17[3678]|18[0-9]|14[57])[0-9]{8}$/,
      message: '请输入正确的手机号'
    }
  ],
  pwd: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入密码'
    }
  ],
  year_limit: [
    {
      required: true,
      trigger: 'blur',
      message: '请填写截止日期'
    }
  ],
  authority: [
    {
      required: true,
      message: '请选择角色权限'
    }
  ],
  company_id: [
    {
      required: true,
      message: '请选择所属店铺'
    }
  ]
}

export const editRules = {
  ...createRules,
  pwd: undefined
}
