<template>
  <el-dialog
    :title="isEdit ? '编辑用户' : '新增用户'"
    :model-value="modelValue"
    @close="closed"
    width="600px"
  >
    <el-form
      class="create-form"
      ref="CreateFromRef"
      size="small"
      :model="createForm"
      :rules="isEdit ? editRules : createRules"
      label-width="120px"
    >
      <el-form-item label="用户名:" prop="name">
        <el-input
          v-model="createForm.name"
          placeholder="请输入"
          name="name"
          type="text"
        />
      </el-form-item>

      <el-form-item label="手机号:" prop="phone">
        <el-input
          v-model="createForm.phone"
          placeholder="请输入"
          name="phone"
          type="text"
        />
      </el-form-item>

      <el-form-item prop="pwd">
        <template #label>
          <el-tooltip
            v-if="isEdit"
            effect="dark"
            content="编辑时，传空值表示不修改原密码"
          >
            <svg-icon icon="tishi"></svg-icon> </el-tooltip
          ><span style="margin-left: 5px">密码:</span>
        </template>
        <template #default>
          <el-input
            v-model="createForm.pwd"
            placeholder="请输入"
            name="pwd"
            type="password"
            show-password
        /></template>
      </el-form-item>

      <el-form-item label="邮箱:" prop="email">
        <el-input
          v-model="createForm.email"
          placeholder="请输入"
          name="email"
          type="text"
        />
      </el-form-item>

      <el-form-item label="日调用次数:" prop="day_limit">
        <el-input
          v-model="createForm.day_limit"
          placeholder="请输入"
          name="day_limit"
          type="text"
        />
      </el-form-item>

      <el-form-item label="月调用次数:" prop="month_limit">
        <el-input
          v-model="createForm.month_limit"
          placeholder="请输入"
          name="month_limit"
          type="text"
        />
      </el-form-item>

      <el-form-item label="截止日期:" prop="year_limit">
        <!-- <div class="wd100">
          <el-input-number
            v-model="createForm.year_limit"
            :min="1"
            :max="100"
            name="year_limit"
          />
          <span style="margin-left: 10px">年</span>
        </div> -->
        <el-date-picker
          v-model="createForm.year_limit"
          type="date"
          placeholder="请选择"
          :disabled-date="disabledDate"
          :shortcuts="shortcuts"
        />
      </el-form-item>

      <el-form-item label="角色权限:" prop="authority">
        <el-select v-model="createForm.authority" placeholder="请选择">
          <!-- <el-option label="平台超管" :value="1" /> -->
          <el-option
            label="管理员"
            :value="2"
            v-permission="['addShopManager']"
          />
          <el-option label="普通管理员" :value="4" />
          <el-option label="采购客户" :value="3" />
        </el-select>
      </el-form-item>

      <el-form-item label="所属店铺:" prop="company_id">
        <el-select v-model="createForm.company_id" placeholder="请选择">
          <el-option
            v-for="list in companyList"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="是否启用:" prop="is_active">
        <el-switch v-model="createForm.is_active" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onConfirmClick" :loading="loading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { addUser, editUser } from '@/api/user'
import { ElMessage } from 'element-plus'
import { createRules, editRules } from '../rule'
import { formatDate } from '@/utils'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  editInfo: {
    type: Object
  },
  companyList: {
    type: Array,
    defalt: () => []
  }
})

const emits = defineEmits(['update:modelValue', 'refresh'])

const shortcuts = [
  {
    text: '一个周',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 7)
      return date
    }
  },
  {
    text: '一个月',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 30)
      return date
    }
  },
  {
    text: '三个月',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 90)
      return date
    }
  },
  {
    text: '半年',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 180)
      return date
    }
  },
  {
    text: '一年',
    value: () => {
      const date = new Date()
      date.setTime(date.getTime() + 3600 * 1000 * 24 * 365)
      return date
    }
  }
]

const disabledDate = (time) => {
  return time.getTime() < Date.now()
}

// 数据源
const loading = ref(false)
const createForm = ref({
  name: '',
  phone: '',
  pwd: '',
  email: '',
  day_limit: '',
  month_limit: '',
  year_limit: '',
  authority: '',
  company_id: '',
  is_active: true
})

const CreateFromRef = ref(null)

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      if (props.isEdit) {
        const formData = {}
        Object.keys(createForm.value).forEach((key) => {
          formData[key] = props.editInfo[key]
        })
        createForm.value = formData
      }
      setTimeout(() => {
        CreateFromRef.value.clearValidate()
      })
    } else {
      CreateFromRef.value.resetFields()
      createForm.value = {
        name: '',
        phone: '',
        pwd: '',
        email: '',
        day_limit: '',
        month_limit: '',
        year_limit: '',
        authority: '',
        company_id: '',
        is_active: true
      }
    }
  }
)

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}

/**
 * 触发表单验证
 */
const onConfirmClick = () => {
  CreateFromRef.value.validate((isValid) => {
    if (isValid) {
      props.isEdit ? postEdit() : postCreate()
    }
  })
}

/**
 * 提交新增
 */
const postCreate = async () => {
  loading.value = true
  await addUser({
    ...createForm.value,
    year_limit: formatDate(createForm.value.year_limit, 'Y-M-D')
  })
  loading.value = false
  ElMessage.success('新增用户成功')
  emits('refresh')
  closed()
}

/**
 * 提交修改
 */
const postEdit = async () => {
  loading.value = true
  await editUser({
    ...createForm.value,
    user_id: props.editInfo.id,
    year_limit: formatDate(createForm.value.year_limit, 'Y-M-D')
  })
  loading.value = false
  ElMessage.success('修改用户成功')
  emits('refresh')
  closed()
}
</script>

<style lang="scss" scoped>
.create-form {
  ::v-deep .el-input {
    width: 400px;
  }
  ::v-deep .wd100 {
    .el-input-number--small {
      width: 150px;
    }
    .el-input {
      width: 100%;
    }
  }
}
</style>
