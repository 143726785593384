import request from '@/utils/request'

/**
 * 获取用户
 */
export const userList = (params) => {
  return request({
    url: '/user',
    method: 'GET',
    params
  })
}

/**
 * 编辑客户
 */
export async function addUser(data) {
  return request({
    url: '/user',
    method: 'POST',
    data
  })
}

/**
 * 编辑客户
 */
export async function editUser(data) {
  return request({
    url: '/user',
    method: 'PUT',
    data
  })
}

/**
 * 删除客户
 */

export const deleteUser = (id) => {
  return request({
    url: '/user',
    method: 'DELETE',
    data: {
      user_ids: [id]
    }
  })
}
