<template>
  <div class="user-container">
    <el-card class="search-header">
      <el-form
        :inline="true"
        ref="SearchFromRef"
        :model="searchForm"
        size="small"
      >
        <div class="search-form">
          <div>
            <el-form-item label="店铺:" prop="company_id">
              <el-select v-model="searchForm.company_id" placeholder="请选择">
                <el-option
                  v-for="list in allCompanies"
                  :key="list.id"
                  :label="list.name"
                  :value="list.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="关键字搜索:" prop="keyword">
              <el-input
                v-model="searchForm.keyword"
                placeholder="请输入关键字"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-button @click="resetSearch">重置</el-button>
              <el-button type="primary" @click="getUserList">查询</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-card>

    <el-card>
      <div class="table-header">
        <el-button type="primary" size="small" @click="openCreateModal">
          + 新增用户
        </el-button>
      </div>
      <el-table :data="allUsers" v-loading="loading" border style="width: 100%">
        <el-table-column label="ID" prop="id" width="80"> </el-table-column>
        <el-table-column label="姓名" prop="name" width="150">
        </el-table-column>
        <el-table-column label="电话号码" prop="phone" width="120">
        </el-table-column>
        <el-table-column label="邮箱" prop="email" width="180">
        </el-table-column>
        <el-table-column label="角色权限" prop="authority" width="100">
          <template #default="scope">
            {{ ROLE_INFO[scope.row.authority].name }}
          </template>
        </el-table-column>
        <el-table-column label="所属店铺" prop="company_name" width="100">
        </el-table-column>
        <el-table-column label="日调用限制" prop="day_limit" width="100">
          <template #default="scope">
            {{ scope.row.day_limit === 0 ? '无限制' : scope.row.day_limit }}
          </template>
        </el-table-column>
        <el-table-column label="月调用限制" prop="month_limit" width="100">
          <template #default="scope">
            {{ scope.row.month_limit === 0 ? '无限制' : scope.row.month_limit }}
          </template>
        </el-table-column>
        <el-table-column label="总调用次数" prop="total_call" width="100">
        </el-table-column>
        <el-table-column label="截止日期" prop="end_time" width="170">
          <template #default="scope">
            {{
              scope.row.year_limit == 0
                ? '无限制'
                : formatDate(scope.row.end_time)
            }}
          </template>
        </el-table-column>
        <el-table-column label="是否启用" prop="is_active" width="100">
          <template #default="scope">
            <el-switch
              v-model="scope.row.is_active"
              :before-change="() => beforeUserActiveChange(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_at" width="170">
        </el-table-column>
        <el-table-column label="更新时间" prop="update_at" width="170">
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          prop="action"
          width="180"
          #default="{ row }"
        >
          <el-button
            v-if="row.authority !== 1"
            type="primary"
            size="mini"
            @click="onEditClick(row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="row.authority !== 1"
            type="danger"
            size="mini"
            @click="onRemoveClick(row)"
          >
            删除
          </el-button>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 50, 100, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <create-modal
      v-model="createModalVisible"
      @refresh="getUserList"
      :is-edit="isEdit"
      :edit-info="editInfo"
      :company-list="allCompanies"
    />
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { companyList } from '@/api/company'
import { userList, editUser, deleteUser } from '@/api/user'
import CreateModal from './components/CreateModal.vue'
import { ROLE_INFO } from '@/constant'
import { formatDate } from '@/utils'

// 数据相关
const allUsers = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)
const allCompanies = ref([])

// 获取店铺列表
const getCompanyList = async () => {
  const result = await companyList()
  allCompanies.value = result.data.list
}

// 获取数据的方法
const loading = ref(false)
const getUserList = async () => {
  loading.value = true
  const result = await userList({
    page: page.value,
    size: size.value,
    keyword: searchForm.keyword !== '' ? searchForm.keyword : undefined,
    company_id: searchForm.company_id !== '' ? searchForm.company_id : undefined
  })
  loading.value = false
  allUsers.value = result.data.list
  total.value = result.data.total
}

// 处理数据不重新加载的问题
onActivated(getUserList)
onActivated(getCompanyList)

// 搜索相关
const SearchFromRef = ref(null)
const searchForm = reactive({
  keyword: '',
  company_id: ''
})

// 重置搜索
const resetSearch = () => {
  SearchFromRef.value.resetFields()
  getUserList()
}

// 改变用户状态
const beforeUserActiveChange = async (item) => {
  const isActive = !item.is_active
  const { error_code: errorCode } = await editUser({
    user_id: item.id,
    is_active: isActive
  })
  ElMessage.success(isActive ? '开启成功' : '禁用成功')
  return +errorCode === 0
}

/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  getUserList()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  getUserList()
}

// 编辑店铺
const editInfo = ref(null)
const isEdit = ref(false)
const onEditClick = (row) => {
  editInfo.value = row
  isEdit.value = true
  createModalVisible.value = true
}

// 删除店铺
const onRemoveClick = (row) => {
  ElMessageBox.confirm(`确定要删除用户【${row.name}】吗？`, {
    type: 'warning'
  }).then(async () => {
    await deleteUser(row.id)
    ElMessage.success('删除成功')
    // 重新渲染数据
    getUserList()
  })
}

// 打开新增店铺弹窗
const createModalVisible = ref(false)
const openCreateModal = () => {
  editInfo.value = null
  isEdit.value = false
  createModalVisible.value = true
}
</script>

<style lang="scss" scoped>
.user-container {
  .search-header {
    margin-bottom: 20px;

    ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
    .search-form {
      display: flex;
      justify-content: space-between;
    }
  }

  .table-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
